import React, { useState } from "react"
//import { useStaticKit, ValidationError } from "@statickit/react"
//import { sendContactEmail } from "@statickit/functions"
import { useForm } from "@statickit/react"

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [state, handleSubmit] = useForm("contact")

  if (state.succeeded) {
    return <div className="md:text-3xl">Du hører fra oss om kort tid!</div>
  }

  /*const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errors, setErrors] = useState([])*/

  /*const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setIsSubmitting(true)
    let resp = await sendContactEmail(client, {
      subject: `${email} submitted the contact form`,
      replyTo: email,
      fields: { message },
    })

    switch (resp.status) {
      case "ok":
        setIsSubmitted(true)
        break

      case "argumentError":
        setErrors(resp.errors)
        setIsSubmitting(false)
        break

      default:
        setIsSubmitting(false)
        break
    }
  }*/

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-8">
        <label
          htmlFor="name"
          className="block mb-2 text-xl font-medium md:text-2xl"
        >
          Navn
        </label>
        <input
          type="text"
          id="name"
          name="name"
          onChange={e => setName(e.target.value)}
          value={name}
          className="w-full h-16 px-4 text-xl bg-transparent border-2 border-gray-900 md:text-2xl focus:border-green-600 focus:outline-none"
        />
      </div>
      <div className="mb-8">
        <label
          htmlFor="email"
          className="block mb-2 text-xl font-medium md:text-2xl"
        >
          E-post
        </label>
        <input
          type="email"
          id="email"
          name="email"
          onChange={e => setEmail(e.target.value)}
          value={email}
          className="w-full h-16 px-4 text-xl bg-transparent border-2 border-gray-900 md:text-2xl focus:border-green-600 focus:outline-none"
        />
      </div>
      <div className="mb-8">
        <label
          htmlFor="message"
          className="block mb-2 text-xl font-medium md:text-2xl"
        >
          Melding
        </label>
        <textarea
          id="message"
          name="message"
          rows={4}
          onChange={e => setMessage(e.target.value)}
          value={message}
          className="w-full px-4 py-4 text-xl bg-transparent border-2 border-gray-900 md:text-2xl focus:border-green-600 focus:outline-none"
        ></textarea>
      </div>
      <button
        disabled={state.submitting}
        type="submit"
        className="w-full h-16 px-4 pt-1 text-xl font-medium text-white bg-gray-900 md:text-2xl focus:bg-green-600 hover:bg-gray-800 focus:outline-none"
      >
        Send
      </button>
    </form>
  )
}

export default ContactForm
