import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import Image from "../components/Image"
import SEO from "../components/Seo"
import Container from "../components/Container"
import ContactForm from "../components/ContactForm"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <h1 className="mt-10 text-3xl font-medium leading-smnug md:leading-tight md:mt-16 lg:mt-32 md:text-5xl lg:text-7xl">
        Vi bygger digitale
        <br />
        løsninger for fremtiden.
      </h1>

      <h2 className="mt-6 text-2xl leading-snug md:mt-8 md:text-4xl lg:text-5xl">
        {/*Pangora er et multidisiplinært studio med <br />
        kompetanse på både utvikling og design, <br />
        og tar deg hele veien fra planlegging til lansering.*/}
        Pangora er et multidisiplinært studio som <br />
        tar deg hele veien fra planlegging til lansering.
      </h2>
    </Container>

    <div
      className="py-16 mt-12 md:py-28 md:mt-28 lg:py-32 lg:mt-32"
      style={{ backgroundColor: "#edf1f5" }}
    >
      <Container>
        <div className="md:w-1/2 lg:w-1/3">
          <h3 className="mb-8 text-2xl font-medium leading-tight md:text-5xl lg:text-7xl">
            Kontakt
          </h3>
          <a
            href="mailto:hei@pangora.no"
            className="text-2xl leading-snug md:text-3xl lg:text-5xl hover:text-green-600"
          >
            hei@pangora.no
          </a>
          <div className="mt-6">
            <ContactForm />
          </div>
        </div>
      </Container>
    </div>
  </Layout>
)

export default IndexPage
